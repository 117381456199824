import React from "react";
import Logo from '../logo.png';
import iconcontact from '../icon-contact.svg'

export const Navigation = () => {
  const openCall = () => {
    const phoneNumber = '919597725388'; // Replace with the phone number you want to call
    const callUrl = `tel:${phoneNumber}`;
    window.open(callUrl);
  };
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>

          <img src={Logo}></img>

        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">


            <li>
              <button href="#team" className="contact" onClick={() => openCall()}>
                <img src={iconcontact} style={{ marginRight: "20px", width: "15px" }} />
                +91 9597725388

              </button>
            </li>
            <li>
              <button href="#team" className="enroll">
                Enroll Now
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
