import React from "react";
import aboutus from '../aboutus.png'

export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <img src={aboutus} style={{width:"100%"}}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
