import React from "react";

import mobline from './mobile.png'

export const Features = (props) => {
  return (
    <div id="features" className="text-center" style={{ backgroundColor: "white" }}>
      <div className="container" style={{ marginTop: "20px", marginBottom: "20px" }}>
        <div className="row">
          <div className="col-lg-12">
            <img
              src={mobline}
              alt="Landing"
              className="responsive-img"
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
