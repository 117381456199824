
import emailjs from "emailjs-com";
import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import 'react-intl-tel-input/dist/main.css'; // Import CSS
import axios from "axios";
import whatapp from '../whatapp.c7b85d402a1d4c6212b873b8a82e0d3c (1).svg'
import callPhone from '../callphone.svg'
import Select from 'react-select';

export const Contact = (props) => {
  const openWhatsApp = () => {
    const phoneNumber = '919597725388'; // Replace with the phone number you want to open
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, '_blank');
  };
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [error, setError] = useState({
    name: "",
    email: "",
    contact: "",
    selectedOption: ""
  })
  const options = [
    { value: 'BA', label: 'BA' },
    { value: 'BBA', label: 'BBA' },
    { value: 'BCA', label: 'BCA' },
    { value: 'B.Com', label: 'B.Com' },
    { value: 'MBA', label: 'MBA' },
    { value: 'MCA', label: 'MCA' },
    { value: 'M.Com', label: 'M.Com' },
    { value: 'MA.JMC', label: 'MA.JMC' },
    { value: 'MA English', label: 'MA English' },
    { value: 'MA Sociology', label: 'MA Sociology' },
    { value: 'MA Political Science', label: 'MA Political Science' },
  ];
  const [selectedOption, setSelectedOption] = useState(null);
  const handleSubmit = (event) => {
    event.preventDefault();

    // Reset error state
    let errors = {
      name: "",
      email: "",
      contact: "",
      selectedOption: ""
    };

    // Basic validation
    let isValid = true;
    if (!name) {
      errors.name = "Name is required";
      isValid = false;
    }

    if (!contact) {
      errors.contact = "Contact is required";
      isValid = false;
    } else {
      const contactRegex = /^\d{10}$/;
      if (!contactRegex.test(contact)) {
        errors.contact = "Invalid contact number";
        isValid = false;
      }
    }

    if (!email) {
      errors.email = "Email is required";
      isValid = false;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        errors.email = "Invalid email format";
        isValid = false;
      }
    }

    if (!selectedOption) {
      errors.selectedOption = "Option is required";
      isValid = false;
    }

    setError(errors);
    if (!isValid) {
      return;
    }

    let data = JSON.stringify({
      contact: contact,
      name: name,
      email: email,
      cou: selectedOption ? selectedOption.value : ''
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.123admissions.com/api/v1/maipalform',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        // addLeadToBitrix24()
        // setSuccess(true)
        alert('Your details have been updated successfully your academic counselor will call you with in 24 hours')
        setSelectedOption(null)
        setName('')
        setContact('')
        setEmail('')

      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="footer">
            <div className="row">
              <div className="col-lg-6">
                <div className="footer-main">
                  <h1>Interested to join
                    our courses?</h1>
                  <small>Share your details and we'll get back to you.</small>
                </div>
              </div>
              <div className="col-lg-6  intro-text" style={{ paddingTop: "2%", paddingBottom: "30px" }}>
                <div className="row" style={{ padding: "40px 10%", backgroundColor: "#f16622", borderRadius: "10px" }}>
                  <div className="col-12">
                    <h3 style={{ color: "white" }}>Enter your details to start the application process</h3>
                  </div>
                  <div className="col-12" style={{ marginTop: "20px" }}>
                    <input className="form-control" placeholder="Enter you Name" value={name}
                      onChange={(e) => setName(e.target.value)}
                      required ></input>
                    <small style={{  color: "white",float:"left" }}>{error?.name}</small>
                  </div>
                  <div className="col-12" style={{ marginTop: "20px" }}>
                    <input className="form-control" value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required placeholder="Enter you email"></input>
                       <small style={{ color: "white",float:"left" }}>{error?.email}</small>
                  </div>
                  <div className="col-12" style={{ marginTop: "20px" }}>
                    <input className="form-control" value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      required placeholder="Enter you Moblie"></input>
                       <small style={{  color: "white",float:"left" }}>{error?.contact}</small>
                  </div>
                  <div className="col-12" style={{ marginTop: "20px", textAlign: "start", color: "black" }}>
                    <Select

                      value={selectedOption}
                      onChange={handleChange}
                      options={options}
                      required
                    />
                     <small style={{  color: "white",float:"left" }}>{error?.selectedOption}</small>
                  </div>
                  <div className="col-12" style={{ marginTop: "20px" }}>
                    <button className="btn btn-primary" style={{ width: "100%", height: "50px", backgroundColor: "blue" }} onClick={handleSubmit}>Apply Now</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="completed">

            <div><a href="https://admissionsmanipal.com/PrivacyPolicy">Privacy Policy</a></div>
            <div>Help Center</div>
          </div>
        </div>
      </div>
      <div className="whatapp">
        <img src={whatapp} style={{ width: "67px", marginRight: "20px", cursor: "pointer", position: "absolute", bottom: "0", marginBottom: "10px", right: "0" }} onClick={openWhatsApp}></img><br></br>

      </div>
    </div>
  );
};
