import React, { useState, useEffect } from "react";
import JsonData from '../../data/data.json'

import { Navigation } from "../../components/navigation";
import { Header } from "../../components/header";
import { Features } from "../../components/features";
import { About } from "../../components/about";
import { Services } from "../../components/services";
import { Gallery } from "../../components/gallery";
import { Contact } from "../../components/contact";


const LandingPage = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);
    const openWhatsApp = () => {
        const phoneNumber = '919500625088'; // Replace with the phone number you want to open
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <>
            <div>
                <Navigation  />
                <Header data={landingPageData.Header} />
                <Features data={landingPageData.Features} />
                <About data={landingPageData.About} />
                <Services data={landingPageData.Services} />
                <Gallery data={landingPageData.Gallery} />
                <Contact data={landingPageData.Contact} />
            </div>
        </>
    )

}
export default LandingPage;