import React, {
  Suspense,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import "./App.css";
import LandingPage from "./page/landingPage/Landingpage";
import PrivacyPolicyComponent from "./page/TermsAndConditions/TermsAndConditions"
import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {


  return (
    <>
      <Router>
        <Suspense>
          <Routes>
            <Route exact path="/" name="Login Page" element={<LandingPage />} />
            <Route exact path="/PrivacyPolicy" name="Login Page" element={<PrivacyPolicyComponent />} />
          </Routes>
        </Suspense>
      </Router>
    </>

  );
};

export default App;
