import React from "react";
import manipal from '../manipal.png'

export const Image = ({ title, fee, du, uni }) => {
  return (
    <div className="portfolio-item">
      <div className="hover-bg">
        {" "}

        <div className="hover-text">
          <h4>{title}</h4>
        </div>
        <div className="card">
          <div className="row">
            <div className="col-12" style={{ marginTop: "2%", width: "100%", padding: "1px 40px", backgroundColor: "#f16622", color: "white" }}>
              <h4 style={{ color: "white" }}>{title}</h4>
            </div>
          </div>
          <div className="row" style={{ display: "flex", marginTop: "10%" }}>
            <div className="col-6" style={{ width: "50%", fontSize: "14px" }}>
              <div>
                {fee}
              </div>
            </div>
            <div className="col-6" style={{ width: "50%", fontSize: "14px" }}>
              <div>
                {du}
              </div>
            </div>


          </div>
          <div className="row">
            <div className="col-12" style={{ marginTop: "2%", width: "100%", padding: "1px 40px", backgroundColor: "rgb(241 102 34 / 12%)", color: "white" }}>
              <img src={manipal} style={{ width: "100%" }}></img>
            </div>
          </div>
        </div>
        {" "}
      </div>
    </div>
  );
};
